import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';
import { AuthService } from '@core/authentication';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { IUser } from '@core/authentication/models/IUser';
import { customPhoneValidator } from '@shared/utils';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  @Output() onClose = new EventEmitter();
  form: FormGroup | any;
  loading: boolean = false;
  submitted: boolean = false;
  showPassword: boolean = false;
  forgetPassword: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private toast: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.createForm();
  }

  navigateToSignUp() {
    this.router.navigate(['auth/signup']).then();
  }

  navigateToRecoverPassword() {
    this.router.navigate(['auth/recover']).then();
  }

  createForm() {
    this.form = new FormGroup({
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
        customPhoneValidator,
      ]),
      password: new FormControl('', Validators.required),
    });
  }

  close() {
    this.onClose.emit();
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.form.valid) {
      this.authService
        .login(this.form.value)
        .pipe(
          tap({
            next: (user: IUser | any) => {
              this.authService.saveUserAfterLogin(user.accessToken);
              this.router.navigate(['dashboard']).then();
            },
            error: (err: any) => {
              this.loading = false;
            },
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.toast.error('ფორმა არასწორია');
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
