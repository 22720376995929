import { DOCUMENT, isPlatformBrowser, Location } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild
} from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@core/authentication';
import { InstructionComponent } from '@shared/components/instruction/instruction.component';
import { ScrollService } from '@shared/services/scroll.service';
import { filter, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('container') container: ElementRef | any;
  @ViewChild('content') content: ElementRef | any;
  loading = true;
  enabledScroll = false;
  showLoading = false;
  touchStartY = 0;
  touchEndY = 0;
  showInstructions = false;
  clickedOnBack = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: any,
    private scrollService: ScrollService,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private renderer: Renderer2,
    private location: Location,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.checkIfMobile()) {
        this.showInstructions = true;
      }
    }
    this.updateScroll();
    this.catchLocationPopstate();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId) && this.authService.getUser()) {
      this.checkIfAuthorized();
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        let loader = this.renderer.selectRootElement('#loader');
        if (loader.style.display != 'none') {
          loader.style.display = 'none';
          this.loading = false;
        }
      }, 1300);
    }
  }

  catchLocationPopstate() {
    this.location.subscribe((event) => {
      if (event && event.type === 'popstate') {
        this.clickedOnBack = true;
      }
    });
  }

  checkIfAuthorized() {
    const unsubscribe$ = new Subject<void>();

    this.authService
      .checkIfAuthorized()
      .pipe(
        takeUntil(unsubscribe$),
        tap({
          next: (res) => {
            if (res?.accessToken) {
              this.authService.saveUserAfterLogin(res.accessToken);
            } else {
              this.authService.logOut();
            }

            unsubscribe$.next();
            unsubscribe$.complete();
          },
        })
      )
      .subscribe();
  }

  updateScroll() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((event: any) => {
          if (event instanceof NavigationEnd) {
            const url = this.router.url;
            const savedPosition = this.scrollService.getScrollPosition(url);

            setTimeout(() => {
              if (
                this.container &&
                typeof this.container.nativeElement.scrollTo === 'function'
              ) {
                this.container.nativeElement.scrollTo(
                  0,
                  this.clickedOnBack ? savedPosition : 0
                );
              }
              this.clickedOnBack = false;
            }, 400);
          }
        })
      )
      .subscribe();
  }

  checkIfMobile() {
    return window?.matchMedia('(display-mode: standalone)')?.matches;
  }

  @HostListener('window:touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    if (event.touches && event.touches?.length > 0) {
      this.touchStartY = event.touches[0].clientY;
    }
  }

  @HostListener('window:touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    if (this.container && event.touches && event.touches?.length > 0) {
      this.touchEndY = event.touches[0].clientY;
      const deltaY = this.touchEndY - this.touchStartY;
       this.content.nativeElement.style.transition = '0s';
      this.content.nativeElement.style.marginTop = `${deltaY / 3}px`;
      if (
        this.container.nativeElement.scrollTop == 0 &&
        deltaY > this.container.nativeElement.clientHeight * 0.35
      ) {
        this.enabledScroll = true;
      } else {
        this.enabledScroll = false;
      }
    }
  }

  @HostListener('window:touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    this.content.nativeElement.style.transition = '0.7s';
    this.content.nativeElement.style.marginTop = `0px`;
    if (this.enabledScroll) {
      this.showLoading = true;
      setTimeout(() => {
        this.enabledScroll = false;
        this.showLoading = false;
      }, 1000);
    }
  }

  onScroll() {
    const url = this.router.url;
    const scrollPosition = this.container.nativeElement.scrollTop;
    this.scrollService.saveScrollPosition(url, scrollPosition);
  }

  scrolled() {
    this.scrollService.scrolled();
  }

  openInstructions() {
    this.dialog.open(InstructionComponent, {
      width: '100%',
      maxWidth: '100%',
    });
  }
}
