import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const routes: Routes | any = [
  {
    name: 'დეშბორდი',
    path: 'dashboard',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    name: 'თელავის ამბები',
    path: 'telavi-news',
    loadChildren: () =>
      import('./features/telavi-news/telavi-news.module').then(
        (m) => m.TelaviNewsModule
      ),
  },
  {
    name: 'სხვადასხვა ამბები',
    path: 'various-news',
    loadChildren: () =>
      import('./features/various-news/various-news.module').then(
        (m) => m.VariousNewsModule
      ),
  },
  {
    name: 'Royal Card',
    path: 'royal-card',
    loadChildren: () =>
      import('./features/royal-card/royal-card.module').then(
        (m) => m.RoyalCardModule
      ),
  },
  {
    name: 'ვაკანსიები',
    path: 'vacancies',
    loadChildren: () =>
      import('./features/vacancies/vacancies.module').then(
        (m) => m.VacanciesModule
      ),
  },
  {
    name: 'სერვისები',
    path: 'services',
    loadChildren: () =>
      import('./features/services/services.module').then(
        (m) => m.ServicesModule
      ),
  },
  {
    name: 'ვაუჩერები',
    path: 'vouchers',
    loadChildren: () =>
      import('./features/vouchers/vouchers.module').then(
        (m) => m.VouchersModule
      ),
  },
  {
    name: 'უძრავი ქონება',
    path: 'real-estate',
    loadChildren: () =>
      import('./features/real-estate/real-estate.module').then(
        (m) => m.RealEstateModule
      ),
  },
  {
    name: 'მაღაზიები',
    path: 'shops',
    loadChildren: () =>
      import('./features/shops/shops.module').then((m) => m.ShopsModule),
  },
  // {
  //   name: 'მიიღე გრანტი',
  //   path: 'grants',
  //   loadChildren: () =>
  //     import('./features/grants/grants.module').then((m) => m.GrantsModule),
  // },
  {
    name: 'ჩემი განცხადებები',
    path: 'my-statements',
    loadChildren: () =>
      import('./features/my-statements/statements.module').then(
        (m) => m.StatementsModule
      ),
  },
  {
    name: 'პროფილის რედაქტირება',
    path: 'my-profile',
    loadChildren: () =>
      import('./features/my-profile/my-profile.module').then(
        (m) => m.MyProfileModule
      ),
  },
  // {
  //   name: 'ჩვენ შესახებ',
  //   path: 'about-us',
  //   loadChildren: () =>
  //     import('./features/about-us/about-us.module').then(
  //       (m) => m.AboutUsModule
  //     ),
  // },
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/authentication/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
