import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../../environments/service/config.service';
import { Observable } from 'rxjs';
import {
  VacanciesObject,
  Vacancy,
} from '@features/vacancies/models/vacancies.model';
import { filterParams } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class VacanciesService {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  getVacancyCategories(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.configService.readConfig.endpoint}/vacancies/categories`
    );
  }

  getVacancies(filter: any): Observable<VacanciesObject> {
    return this.http.get<VacanciesObject>(
      `${this.configService.readConfig.endpoint}/vacancies`,
      {
        params: filterParams(filter),
      }
    );
  }

  getVacancyById(id: number): Observable<Vacancy> {
    return this.http.get<Vacancy>(
      `${this.configService.readConfig.endpoint}/vacancies/${id}`
    );
  }

  addVacancy(vacancy: Vacancy): Observable<Vacancy> {
    return this.http.post<Vacancy>(
      `${this.configService.readConfig.endpoint}/vacancies`,
      vacancy
    );
  }

  updateVacancy(vacancy: Vacancy): Observable<Vacancy> {
    return this.http.put<Vacancy>(
      `${this.configService.readConfig.endpoint}/vacancies/${vacancy.id}`,
      vacancy
    );
  }

  deleteVacancy(id: number) {
    return this.http.delete(
      `${this.configService.readConfig.endpoint}/vacancies/${id}`
    );
  }
}
