import { Injectable } from '@angular/core';
import { environment } from '../environment';

@Injectable()
export class ConfigService {
  get readConfig(): EnvModel {
    return environment;
  }
}

export interface EnvModel {
  production?: boolean;
  version?: string;
  endpoint?: string;
  auth0?: string;
}
