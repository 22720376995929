import { NgModule } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClientXsrfModule,
} from '@angular/common/http';
import { ServerErrorInterceptor } from '@core/errors';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@core/authentication';
import { CsrfInterceptor } from '@core/errors/interceptors/csrf.interceptor';
import { AlreadyLoggedInGuard } from '@core/authentication/guards/already-logged-in.guard';
import { LoginRequiredGuard } from '@core/authentication/guards/login-required-guard.guard';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrf-token',
      headerName: 'x-csrf-token',
    }),
  ],
  providers: [
    AlreadyLoggedInGuard,
    LoginRequiredGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
