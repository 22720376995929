<div>
  <p>
    ეს არის MyTelavi.ge-ს ვებ-გვერდი. თქვენ მიერ ამ ვებ-გვერდის გამოყენება
    გულისხმობს ქვემოთ მოყვანილი პირობების აღიარებას ვებ-გვერდზე ყოველი
    შესვლისას.
  </p>
  მომსახურების შეფერხება ან შეწყვეტა მიუხედავად იმისა, რომ ჩვენ ვცდილობთ
  უზრუნველვყოთ წინამდებარე ვებ-გვერდის მაღალი სტანდარტები და მისი უწყვეტი
  ხელმისაწვდომობა, ინტერნეტი არ არის სტაბილური სივრცე და ხარვეზებმა,
  მომსახურების შეფერხებამ ან წყვეტამ შესაძლოა თავი იჩინოს ნებისმიერ დროს. ჩვენ
  არ ვიღებთ საკუთარ თავზე პასუხისმგებლობას ვებ-გვერდზე ხარვეზის, მომსახურების
  შეფერხების ან წყვეტის შემთხვევაში ან ვებ-გვერდის (ან მისი რომელიმე ნაწილის)
  ოპერირების ვალდებულებას. დროდადრო ჩვენ შეიძლება განვაახლოთ ვებ-გვერდის
  შიგთავსი ან/და მისი თვისებები შეტყობინების გარეშე.

  <p>ბმულები სხვა ვებ-გვერდებთან</p>
  წინამდებარე ვებ-გვერდზე თქვენ შესაძლებლობა გექნებათ გამოიყენოთ ავტომატური
  ბმულები სხვა ვებ-გვერდებთან, რომლებიც ჩვენი აზრით თქვენთვის საინტერესო იქნება.
  ჩვენ არ ვიღებთ პასუხისმგებლობას საკუთარ თავზე იმ ვებ-გვერდებთან მიმართებით,
  რომელთა მფლობელებსაც არ აქვთ ჩვენთან კავშირი (კომერციული ან სხვა). ავტომატური
  ბმულების გამოყენება ამ ვებ-გვერდებთან დასაკავშირებლად თქვენი ცალმხრივი
  გადაწყვეტილებაა.

  <p>თქვენ მიერ განთავსებული ინფორმაცია</p>
  ჩვენ გამოვიყენებთ თქვენ მიერ მოწოდებულ ინფორმაციას (მათ შორის პირად
  ინფორმაციას შეზღუდვების გარეშე) რეკრუტირების პროცესის და მისგან გამომდინარე
  ადმინისტრაციული ფუნქციების განსახორციელებლად. ეს მოიცავს ჩვენ მიერ, სხვა
  ქმედებებთან ერთად, მონაცემების (მათ შორის პირადი ინფორმაცია შეზღუდვების
  გარეშე) დამუშავებასა და განთავსებას და პოტენციური დამსაქმებლებისა და
  კლიენტებისათვის მათ გადაცემას ან ვებ-გვერდის მეშვეობით ხელმისაწვდომობას;
  ინფორმაცია დასაქმების, ვაკანსიის შესახებ პირდაპირ გადაეცემა კანდიდატებს და
  შეიძლება დაიდოს. ჩვენ ვიყენებთ მესამე პირის დახმარებას, როგორც რეკრუტირების
  პროცესის შემადგენელ ნაწილს, თქვენი მონაცემების დასამუშავებლად. ჩვენ შეიძლება
  შევაგროვოთ და მოვიძიოთ მონაცემები თქვენ მიერ მოწოდებული ინფორმაციიდან, რათა
  უკეთ გავიგოთ ჩვენი მომხმარებლების, როგორც ჯგუფის, საჭიროებები და გაგიწიოთ
  უკეთესი მომსახურება. ჩვენ ასევე შეიძლება გავუზიაროთ მოძიებული და შეგროვებული
  ინფორმაცია წინასწარ განსაზღვრულ მესამე პირს, პირადი მონაცემებისა და
  მაიდენტიფიცირებელი ინფორმაციის მიწოდების გარეშე. თქვენ აცხადებთ თანხმობას, რომ
  ჩვენ გამოვიყენებთ თქვენ მიერ მოწოდებულ ინფორმაციას (მათ შორის პირად
  ინფორმაციას შეზღუდვების გარეშე) ნებისმიერი ხსენებული ხერხით. გთხოვთ
  გაითვალისწინოთ, რომ ყველა მესამე პირი - სააგენტოები ან/და კომპანიები
  ეთანხმებიან ჩვენ პირობებს და მათ მიერ პირობების დარღვევის დაფიქსირების
  შემთხვევაში მათ აეკრძალებათ ჩვენი მომსახურებით სარგებლობა. მონაცემებს, რომელიც
  თქვენ მოგვაწოდეთ ინტერნეტის განაცხადის ან სარეგისტრაციო ფორმის, ან რაიმე სხვა
  ფორმის მეშვეობით, რომელიც თქვენ შეავსეთ ვებ-გვერდის გამოყენების დროს, ჩვენ
  ვეპყრობით მონაცემების დაცვასთან დაკავშირებული საქართველოს კანონმდებლობის სრული
  დაცვით. უფრო საფუძვლიანი ინფორმაცია პირად მონაცემებზე განმარტებულია
  „კონცეფციაში კონფიდენციალობის შესახებ“.

  <p>დასაქმების პირობები</p>
  ნებისმიერი განცხადების განთავსება MyTelavi.ge-ს გამოყენებით რეგულირდება ჩვენი
  სტანდარტული დასაქმების პირობების შესაბამისად. ცალკე პირობები ასევე არეგულირებს
  ჩვენი დამქირავებლის ვებ-გვერდის გამოყენებას.

  <p>ინტელექტუალური საკუთრება</p>
  ვებ-გვერდზე განთავსებულ მონაცემებს იცავს ადგილობრივი და საერთაშორისო
  კანონმდებლობა საავტორო უფლებების და პატენტის შესახებ და თქვენ ეთანხმებით
  გამოიყენოთ ვებ-გვერდი იმგვარად რომ არ შეილახოს ეს უფლებები. თქვენ უფლება გაქვთ
  გადაიღოთ ვებ-გვერდზე განთავსებული მასალების ასლები პირადი მოხმარებისთვის,
  თუმცა ასლის გადაღება კომერციული ან სამეწარმეო საქმიანობის მიზნით დაუშველებია.

  <p>უსაფრთხოება და პაროლი</p>
  იმისათვის რომ დარეგისტრირდეთ ვებ-გვერდზე და გაიაროთ ავტორიზაცია, თქვენ
  დაგჭირდებათ მომხმარებლის სახელი და პაროლი. პაროლის უსაფრთხოების
  პასუხისმგებლობა ერთპიროვნულად გეკისრებათ. მისი კონფიდენციალობა უნდა იყოს
  დაცული და მისი გამხელა სხვა პირისთვის დაუშვებელია. უნდა გვაცნობოთ დაუყოვნებლივ
  თუ თქვენ მიგაჩნიათ, რომ თქვენი პაროლი ცნობილი გახდა მესამე პირისათვის ან იგი
  შეიძლება გამოყენებული იქნას არაუფლებამოსილი პირის მიერ. ჩვენ არ ვართ
  პასუხისმგებელნი პაროლის არასათანადო ან არაავტორიზებულ გამოყენებაზე ან მისი
  სხვა პირისთვის გადაცემაზე.

  <p>რეგისტრაციის შეწყვეტა</p>
  ჩვენ ვიტოვებთ უფლებას შეგიწყვიტოთ რეგისტრაცია ან/და შევზღუდოთ თქვენი წვდომა
  ვებ-გვერდზე ან მის რომელიმე ნაწილზე (მათ შორის მომსახურეობაზე, პროდუქტებზე ან
  ვებ-გვერდზე არსებულ ან მისი მეშვეობით მოპოვებულ ინფორმაციაზე) ყოველგვარი
  ახსნა-განმარტების ან შეტყობინების გარეშე.

  <p>პასუხისმგებლობა</p>
  ჩვენ არ ვართ პასუხიმგებელნი დანაკარგზე (პირდაპირი ან არაპირდაპირი,
  სამსახურებრივი დანაკარგები, შემოსავალი ან მოგება, გახარჯული თანხები, მონაცემთა
  დაზიანება ან რაიმე სხვა არაპირდაპირი თანამდევი დანაკარგი), რომელიც შეიძლება
  წარმოიშვას თქვენ მიერ ვებ-გვერდის გამოყენებისას და გამოვრიცხავთ ამგვარ
  პასუხისმგებლობას კონტრაქტებში, სამოქალაქო გადაცდომაში (მათ შორის
  გაუფრთხილებლობით) თუ სხვა. ჩვენ აქვე გამოვრიცხავთ ჩვენი მხრიდან ნებისმიერ
  წარმომადგენლობას, გარანტიებს, პირობებს ამ ვებ-გვერდთან მიმართებით და თქვენ
  მიერ მის გამოყენებასთან დაკავშირებით კანონით მაქსიმალურად დაშვებული ზღვარის
  ფარგლებში. თქვენ აცხადებთ თანხმობას გაგვათავისუფლოთ ნებისმიერი ხარჯის,
  დანაკარგის, პასუხიმგებლობის ან მოქმედებისაგან, რომელიც შეიძლება წარმოიშვას
  თქვენ მიერ ვებ-გვერდის სათანადოდ ან ბოროტად გამოყენების შემთხვევაში.
  დაუყოვნებლივ უნდა გვაცნობოთ თუ ვინმე ასაჩივრებს ან იმუქრება გასაჩივრებით თქვენ
  მიერ ვებ-გვერდის გამოყენებასთან დაკავშირებით.

  <p>კანონმდებლობა და იურისდიქცია</p>
  ვებ-გვერდის გამოყენება, ასევე ნებისმიერი ხელშეკრულება, რომელიც ძალაში შევა ამ
  ვებ-გვერდის მეშვეობით რეგულირდება საქართველოს კანონმდებლობით. ნებისმიერი დავის
  გადაწყვეტა, რომელიც წარმოიშვება ვებ-გვერდის გამოყენებისას ან მისი მეშვეობით
  ძალაში შესული ხელშეკრულების მოქმედებისას, საქართველოს სასამართლოს სრულ
  პრეროგატივას განეკუთვნება. ზოგიერთი პროდუქცია ან მომსახურება, რომელიც
  ხელმისაწვდომია ამ ვებ-გვერდის მეშვეობით შეიძლება იყოს უკანონო ან სხვაგვარად
  აკრძალული საქართველოს ფარგლებს გარეთ. თუ თქვენ შეუკვეთავთ, მიიღებთ, შეიძენთ ან
  სხვა სახის სარგებელს ნახავთ ამგვარი პროდუქციისა თუ მომსახურების მეშვეობით,
  ჩვენ არ ვიღებთ პასუხისმგებლობას საკუთარ თავზე ნებისმიერ დანაკარგზე, რომელიც
  აცილებული იქნებოდა თუ თქვენ გამოიყენებდით ვებ-გვერდს როგორც საქართველოს
  რეზიდენტი პირი (აქ ასევე იგულისხმება პასუხისმგებლობა იმ კანონების,
  რეგულაციების, პრაქტიკის წინაშე, რომელიც ზღუდავს ჩვენ საქმიანობას სხვა
  ქვეყნებში). პირობების შეცვლა და ძალადაკარგულად გამოცხადება ჩვენ შეიძლება
  შევცვალოთ ვებ-გვერდის პირობები ნებისმიერ დროს. იგულისხმება, რომ თქვენ მიიღებთ
  შეცვლილ პირობებს თქვენ მიერ პირობების ცვლილების შემდეგ ვებ-გვერდის
  გამოყენებისას. საქართველოს სასამართლოს მიერ რომელიმე პირობის ძალადაკარგულად
  გამოცხადების შემთხვევაში სხვა პირობები უცვლელად ძალაში რჩება.
  <div *ngIf="!data?.hideFooter" class="footer-buttons">
    <button
      type="submit"
      mat-raised-button
      color="primary"
      (click)="accept.emit()"
    >
      ვეთანხმები
    </button>
    <button mat-stroked-button (click)="cancel.emit()">არა</button>
  </div>
  <div *ngIf="data?.hideFooter" class="footer-buttons">
    <button mat-stroked-button (click)="cancel.emit()">დახურვა</button>
  </div>
</div>
