import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthValidationService } from '@core/authentication/services/auth-validation/auth-validation.service';
import { AuthService } from '@core/authentication';
import { Subject, takeUntil, tap } from 'rxjs';
import { SMSVerify } from '@core/authentication/models/auth.model';
import { Router } from '@angular/router';
import { customEmailValidator, customPhoneValidator } from '@shared/utils';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from "@shared/components/terms-and-conditions/terms-and-conditions.component";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  form: FormGroup | any;
  loading = false;
  submitted = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  gender = new FormControl(null, [Validators.required]);
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private toast: ToastrService,
    private authValidationService: AuthValidationService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup(
      {
        firstname: new FormControl('', Validators.required),
        lastname: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, customEmailValidator]),
        phone: new FormControl('', [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9),
          customPhoneValidator,
        ]),
        gender: this.gender,
        birthDate: new FormControl(null, Validators.required),
        terms: new FormControl(null, Validators.required),
        code: new FormControl('', Validators.required),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
        ]),
        confirm_password: new FormControl(
          '',
          Validators.compose([Validators.required])
        ),
      },
      this.authValidationService.passwordMatch('password', 'confirm_password')
    );
  }

  sendSms() {
    if (this.form.controls.phone?.valid && this.form.value?.phone) {
      this.authService
        .sendSms({ phone: this.form.value?.phone })
        .pipe(
          tap(() => {
            this.toast.success('კოდი გაგზავნილია ნომერზე');
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.toast.error('გთხოვთ შეიყვანოთ ტელეფონის ნომერი');
    }
  }

  openTermsAndConditions() {
    const modalRef = this.dialog.open(TermsAndConditionsComponent, {
      width: '70%',
      height: '70%',
      autoFocus: false,
    });

    modalRef.componentInstance.accept.subscribe(() => {
      this.form.controls.terms.setValue(true);
      modalRef.close();
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.close();
    });
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.form.valid) {
      const phone: SMSVerify = {
        phone: this.form.value.phone,
        code: this.form.value.code,
      };
      this.authService
        .smsVerify(phone)
        .pipe(
          tap({
            next: (res) => {
              this.registerValidUser();
            },
            error: (err: any) => {
              this.loading = false;
            },
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.toast.error('ფორმა არასწორია');
      this.loading = false;
    }
  }

  registerValidUser() {
    this.authService
      .register(this.form.value)
      .pipe(
        tap({
          next: (user: any) => {
            this.authService.saveUserAfterLogin(user.accessToken);
            this.router.navigate(['dashboard']).then();
            this.toast.success('წარმატებით გაიარეთ რეგისტრაცია');
          },
          error: (err: any) => {
            this.loading = false;
          },
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
