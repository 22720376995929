import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appOutsideClick]',
})
export class OutsideClickDirective implements OnInit, OnDestroy {
  @Input() appOutsideClickActive = true;
  @Input() appOutsideClickDelay = 200; // Adjust the delay as needed
  @Output() appOutsideClick = new EventEmitter();

  private timeoutId: any;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if (this.appOutsideClickActive) {
      this.activateClickListener();
    }
  }

  private activateClickListener() {
    this.timeoutId = setTimeout(() => {
      document.addEventListener('click', this.onClick);
    }, this.appOutsideClickDelay);
  }

  private clearTimeout() {
    clearTimeout(this.timeoutId);
    document.removeEventListener('click', this.onClick);
  }

  private onClick = (event: Event) => {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.appOutsideClick.emit();
    }
  };

  ngOnDestroy() {
    this.clearTimeout();
  }
}
