import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../../environments/service/config.service';
import { Observable } from 'rxjs';
import { filterParams } from '@shared/utils';
import {
  IService,
  IServiceObject,
} from '@features/services/models/services.model';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  getServiceCategories(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.configService.readConfig.endpoint}/services/categories`
    );
  }

  getServices(filter: any): Observable<IServiceObject | any> {
    return this.http.get<IServiceObject | any>(
      `${this.configService.readConfig.endpoint}/services`,
      {
        params: filterParams(filter),
      }
    );
  }

  getServiceById(id: number): Observable<IServiceObject | any> {
    return this.http.get<IServiceObject | any>(
      `${this.configService.readConfig.endpoint}/services/${id}`
    );
  }

  addService(service: IService | any): Observable<any> {
    return this.http.post<any>(
      `${this.configService.readConfig.endpoint}/services`,
      service
    );
  }

  updateService(service: IService | any, id: number): Observable<any> {
    return this.http.put<any>(
      `${this.configService.readConfig.endpoint}/services/${id}`,
      service
    );
  }

  deleteService(id: number): Observable<any> {
    return this.http.delete<any>(
      `${this.configService.readConfig.endpoint}/services/${id}`
    );
  }
}
