import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-my-telavi-ads',
  templateUrl: './my-telavi-ads.component.html',
  styleUrls: ['./my-telavi-ads.component.scss']
})
export class MyTelaviAdsComponent {
  @Input() title: any = '';
  @Input() link: any = '';
  @Input() image: any = '';
  @Input() showSkeleton: boolean = false;
  @Output() hide = new EventEmitter<void>();

  openNewTab() {
    window.open(this.link, '_blank');
  }

  close() {
    this.hide.emit();
  }
}
