import { Component, OnDestroy, OnInit } from '@angular/core';
import { routes } from '../../app-routing.module';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@core/authentication';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { SearchService } from '@shared/services/search.service';
import { NewsType } from '@shared/models/news.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  routes: any = [];
  showMenu = false;
  showLogin = false;
  showSearch = false;
  showDetails = false;

  options: any[] = [];
  filter: any = {
    limit: 10,
    search: '',
  };
  searchSubject = new Subject<string>();
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public readonly authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private searchService: SearchService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showLogin = !event.url.includes('login');
      }
    });
  }

  ngOnInit(): void {
    this.initRoutes();
    this.showLogin = true;
    this.searchSubjectPipe();
  }

  getSearchOptions() {
    this.searchService
      .search(this.filter)
      .pipe(
        tap({
          next: (res: any) => {
            this.options = res.news;
          },
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  searchSubjectPipe() {
    this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        this.getSearchOptions();
      });
  }

  search() {
    this.searchSubject.next(this.filter.search);
  }

  onSelectionChange(option: any) {
    setTimeout(() => {
      this.filter.search = '';
    }, 1000);
    this.showSearch = false;
    this.router
      .navigate([
        `${option.type == NewsType.TELAVI ? 'telavi-news' : 'various-news'}/${
          option.id
        }`,
      ])
      .then();
  }

  onClose() {
    this.showSearch = false;
    this.filter.search = '';
  }

  private initRoutes() {
    this.routes = routes.filter((route: any) => {
      return (
        route.name &&
        route.path !== 'dashboard' &&
        route.path !== 'my-statements' &&
        route.path !== 'my-profile'
        //  && this.authService.allExcept(route.data?.rolesExcept)
      );
    });
  }

  login() {
    this.router.navigate(['auth/login']).then();
  }

  displayFn(option: any): string {
    return option ? option.title : '';
  }

  hideMenuAndDetails() {
    this.showDetails = false;
    this.showMenu = false;
  }

  logout() {
    this.authService
      .logOut()
      .pipe(
        tap({
          next: () => {
            this.showMenu = false;
            this.showDetails = false;
            this.showLogin = true;
            this.authService.clearStorage();
          },
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
