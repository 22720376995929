import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { RegistrationComponent } from '@core/authentication/components/registration/registration.component';
import { LoginPageComponent } from '@core/authentication/components/login/login-page.component';
import { RecoverPasswordComponent } from '@core/authentication/components/recover/recover-password.component';
import { AlreadyLoggedInGuard } from '@core/authentication/guards/already-logged-in.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'signup',
    component: RegistrationComponent,
    canActivate: [AlreadyLoggedInGuard],
    data: {
      PageName: 'Signup',
    },
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [AlreadyLoggedInGuard],
    data: {
      PageName: 'Login',
    },
  },
  {
    path: 'recover',
    component: RecoverPasswordComponent,
    canActivate: [AlreadyLoggedInGuard],
    data: {
      PageName: 'Recover',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
