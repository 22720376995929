import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from "@shared/components/terms-and-conditions/terms-and-conditions.component";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(private dialog: MatDialog) {}

  navigateOnFb() {
    window.open('https://www.facebook.com/MyTelavi.ge', '_blank');
  }

  openTermsAndConditions() {
    const modalRef = this.dialog.open(TermsAndConditionsComponent, {
      width: '70%',
      height: '70%',
      autoFocus: false,
      data: {
        hideFooter: true,
      }
    });

    modalRef.componentInstance.accept.subscribe(() => {
      modalRef.close();
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.close();
    });
  }
}
