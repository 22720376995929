import { AbstractControl } from '@angular/forms';

export function isNullOrUndefined(val: any) {
  return val === 'undefined' || val === null;
}

export function deleteNullProperties(val: any) {
  for (let propName in val) {
    if (isNullOrUndefined(val[propName])) {
      delete val[propName];
    }
  }
}

export function randomHex(count: number = 2) {
  let out: string = '';
  for (let i: number = 0; i < count; i++) {
    out += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return out;
}

function getChildren(tree: any, indent: number = 1): any[] {
  let result: any[] = [];
  if (tree.children) {
    tree.childrenCount = tree.children.length;
    tree.children.forEach((child: { indent: number }) => {
      child.indent = indent;
      result.push(child);
      result = result.concat(getChildren(child, indent + 1));
    });
  } else {
    tree.childrenCount = 0;
  }

  delete tree.children;
  delete tree.childrenIds;
  delete tree.childrenCount;
  delete tree.indent;
  return result;
}
export function flattenArray(value: any[]): any[] {
  let result: any[] = [];
  if (value) {
    value.forEach((tree) => {
      tree.indent = 0;
      result.push(tree);
      result = result.concat(getChildren(tree));
    });
  }
  return result;
}

export const filterParams = (obj: any) =>
  Object.keys(obj ?? {}).reduce((acc: any, key) => {
    const isNotVoid = obj[key] !== void 0,
      isNotNull = obj[key] !== null,
      isNotEmptyString = obj[key] !== '',
      isNotFalse = obj[key] !== false,
      isNotUndefined = obj[key] !== undefined;

    if (
      isNotVoid &&
      isNotNull &&
      isNotEmptyString &&
      isNotFalse &&
      isNotUndefined
    ) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});

export const years = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 90;
  const years: number[] = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }
  return years;
};

export function customEmailValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  // Your custom validation logic here
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  if (control.value && !pattern.test(control.value)) {
    return { invalidEmail: true };
  }

  return null;
}

export function customPhoneValidator(control: AbstractControl) {
  const value = control.value;

  if (isNaN(value)) {
    return { notANumber: true };
  }

  if (!value.toString().startsWith('5')) {
    return { notStartingWith5: true };
  }

  return null; // Validation passed
}

export function stripHtmlTags(html: string): string {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
  // Alternatively, you can use a regular expression to remove HTML tags:
  // return html.replace(/<[^>]*>/g, '');
}

export function limitText(text: string, limit: number): string {
  return text.length <= limit ? text : text.substring(0, limit).trim();
}

export const stripHtmlTagsSSR = (html: string): string => {
  return html.replace(/<[^>]*>/g, '');
};
