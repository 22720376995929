import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { firstValueFrom, from, lastValueFrom, Observable } from 'rxjs';
import { AuthService } from '@core/authentication';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next));
  }

  async handle(req: HttpRequest<unknown>, next: HttpHandler) {
    let headers: HttpHeaders = req.headers;
    if (
      req.method === 'POST' ||
      req.method === 'PUT' ||
      req.method === 'PATCH' ||
      req.method === 'DELETE'
    ) {
      const csrf = await firstValueFrom(this.authService.getCsrfToken());
      headers = headers.append('csrf-token', csrf);
    }

    const modifiedRequest = req.clone({
      headers,
      withCredentials: true,
    });

    return await lastValueFrom(next.handle(modifiedRequest));
  }
}
