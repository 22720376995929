import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IService } from '@features/services/models/services.model';
import { ServicesService } from '@features/services/services/services.service';
import { MetaService } from '@shared/services/meta.service';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss'],
})
export class ServiceDetailsComponent implements OnInit, OnDestroy {
  service: IService | any;
  showSkeleton = true;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private servicesService: ServicesService,
    private metaService: MetaService
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const id = +params['id'];
      this.getServiceById(id);
    });
  }

  getServiceById(serviceId: number) {
    if (serviceId)
      this.servicesService
        .getServiceById(serviceId)
        .pipe(
          tap({
            next: (res: any | IService) => {
              this.service = res.data;
              this.metaService.updateMetaTags({
                tabTitle: 'სერვისები',
                title: this.service.title,
                description: this.service.description,
                image: this.service.image,
                url: `https://mytelavi.ge/services/details/${this.service.id}`,
              });
              this.showSkeleton = false;
            },
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
