import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private subject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  subjects$: Observable<boolean> = this.subject.asObservable();
  scrollPositions: { [url: string]: number } = {};

  constructor() {}

  scrolled() {
    this.subject.next(true);
  }

  handleScroll(): Observable<boolean> {
    return this.subjects$;
  }

  saveScrollPosition(url: string, position: number) {
    this.scrollPositions[url] = position;
  }

  getScrollPosition(url: string): number {
    return this.scrollPositions[url] || 0;
  }
}
