import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IUser } from '@core/authentication/models/IUser';
import {
  Login,
  RecoverPassword,
  Register,
  SMS,
  SMSVerify,
} from '@core/authentication/models/auth.model';
import { Observable, Subject, map } from 'rxjs';
import { ConfigService } from '../../../../../environments/service/config.service';

@Injectable()
export class AuthService {
  private readonly USER_KEY = 'user';
  fullName = new Subject<string>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    private configService: ConfigService,
    private router: Router
  ) {}

  refreshToken() {
    return this.http.post(
      `${this.configService.readConfig.auth0}/auth/refresh`,
      {}
    );
  }

  login(body: Login): Observable<IUser | any> {
    return this.http.post<IUser | any>(
      `${this.configService.readConfig.auth0}/auth/signin`,
      body,
      {
        headers: {},
        withCredentials: true,
      }
    );
  }

  logOut(): Observable<IUser | any> {
    return this.http.post<any>(
      `${this.configService.readConfig.auth0}/auth/signout`,
      {}
    );
  }

  register(body: Register): Observable<any> {
    return this.http.post<any>(
      `${this.configService.readConfig.auth0}/auth/signup`,
      body
    );
  }

  sendSms(body: SMS): Observable<any> {
    return this.http.post<any>(
      `${this.configService.readConfig.auth0}/auth/sms/send`,
      body
    );
  }

  smsVerify(body: SMSVerify): Observable<any> {
    return this.http.post<any>(
      `${this.configService.readConfig.auth0}/auth/sms/verify`,
      body
    );
  }

  forgotPassword(body: RecoverPassword): Observable<any> {
    return this.http.post<any>(
      `${this.configService.readConfig.auth0}/auth/forgot-password`,
      body
    );
  }

  getCsrfToken(): Observable<any> {
    return this.http
      .get<any>(`${this.configService.readConfig.auth0}/auth/token`, {
        headers: {},
        withCredentials: true,
      })
      .pipe(
        map((res: any) => {
          return res.token;
        })
      );
  }

  checkIfAuthorized(): Observable<any> {
    return this.http
      .get<any>(`${this.configService.readConfig.auth0}/auth/me`, {
        headers: {},
        withCredentials: true,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  isAuthenticated(): boolean {
    try {
      const user = this.getUser();
      const result = user != null;
      return result;
    } catch (e) {
      return false;
    }
  }

  getUser(): any {
    if (isPlatformBrowser(this.platformId)) {
      const user = localStorage.getItem(this.USER_KEY) as IUser | any;
      if (user) {
        return JSON.parse(user);
      }
    }

    return null;
  }

  saveUserAfterLogin(accessToken: string) {
    const jwtHelper = new JwtHelperService();
    const tokenPayload = jwtHelper.decodeToken(accessToken);
    const user: IUser | any = {
      fullName: tokenPayload.fullname,
      email: tokenPayload.email,
      phone: tokenPayload.phone,
    };
    this.saveUser(user);
  }

  saveUser(user: IUser | any): void {
    localStorage.removeItem(this.USER_KEY);
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));
  }

  setFullName(FullName: string): void {
    this.fullName.next(FullName);
  }

  getFullName(): Observable<string> {
    return this.fullName.asObservable();
  }

  clearStorage() {
    this.router.navigate(['auth/login']).then();
    localStorage.clear();
  }
}
