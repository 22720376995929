<div #ref>
  <p>როგორ დავამატოთ აპლიკაცია ტელეფონში</p>
  <iframe
    width="{{ ref.offsetWidth - 50 }}px"
    height="{{ (ref.offsetWidth / 16) * 9 }}px"
    src="https://www.youtube.com/embed/P8mpCGTA628?si=Ppswkrg5nx4BuA_p"
    title="აპლიკაციის დამატების ინსტრუქცია"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  ></iframe>
  <mat-dialog-actions align="end">
    <button mat-stroked-button [mat-dialog-close]="true">დახურვა</button>
  </mat-dialog-actions>
</div>
