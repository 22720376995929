<form [formGroup]="form" id="form">
  <div class="title">
    <mat-label>ბარათის მოთხოვნა</mat-label>
  </div>

  <div class="form-container">
    <div class="d-container">
      <mat-form-field class="hide-wrapper" appearance="outline">
        <mat-label>სახელი</mat-label>
        <input matInput formControlName="firstname"/>
      </mat-form-field>

      <mat-form-field class="hide-wrapper" appearance="outline">
        <mat-label>გვარი</mat-label>
        <input matInput formControlName="lastname"/>
      </mat-form-field>

      <mat-form-field class="hide-wrapper" appearance="outline">
        <mat-label>ელ.ფოსტა</mat-label>
        <input matInput formControlName="email"/>
      </mat-form-field>

      <mat-form-field class="hide-wrapper" appearance="outline">
        <mat-label>ტელეფონი</mat-label>
        <input [maxlength]="9" matInput formControlName="phone"/>
      </mat-form-field>

      <mat-form-field class="hide-wrapper" appearance="outline">
        <mat-label>მისამართი</mat-label>
        <input matInput formControlName="address"/>
      </mat-form-field>

      <mat-form-field class="hide-wrapper" appearance="outline">
        <mat-label>ბარათის ტიპი</mat-label>
        <mat-select formControlName="royalCardId">
          <mat-option *ngFor="let card of royalCards$ | async" [value]="card.id">
            {{ card.name + ' - ' + card.price }}₾
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="footer-buttons">
    <button
      type="submit"
      mat-raised-button
      color="primary"
      form="form"
      [disabled]="loading"
      (click)="onSubmit()"
    >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      მოთხოვნა
    </button>
    <button mat-stroked-button (click)="close.emit()">გაუქმება</button>
  </div>
</form>
