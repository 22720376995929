<div class="vacancy-details-container">
  <ngx-skeleton-loader
    *ngIf="showSkeleton"
    [count]="1"
    [theme]="{ height: '20px' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    *ngIf="showSkeleton"
    [count]="1"
    [theme]="{ height: '40px' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    *ngIf="showSkeleton"
    [count]="1"
    [theme]="{ height: '100px' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    *ngIf="showSkeleton; else view"
    [count]="1"
    [theme]="{ height: '150px' }"
  ></ngx-skeleton-loader>
  <ng-template #view>
    <div class="vacancy-details-header">
      <div class="location">
        <mat-icon>location_on</mat-icon>
        <span class="address">{{ vacancy?.location?.name }}</span>
        <!--        <span>ნახვა: {{ vacancy.views }}</span>-->
        <span class="date">{{
          vacancy.publishedAt | date : "dd/MM/yy HH:mm"
        }}</span>
      </div>
      <div class="vacancy-title">
        <span>{{ vacancy.title }}</span>
      </div>
    </div>
    <div class="statement-details-content">
      <div class="statement-details-item">
        <span class="statement-title desc">აღწერილობა</span>
        <div class="statement-text" [innerHTML]="vacancy?.description | richText"></div>
      </div>
      <div class="statement-details-item contact-card">
        <div class="box box-img">
          <img
            src="{{ vacancy?.company?.logo }}"
            alt="img"
            width="150"
            height="150"
          />
        </div>
        <div class="contact-box">
          <span class="statement-title contact">კონტაქტი</span>
          <div class="statement-text contact">
            <div class="box">
              <mat-icon class="work">work</mat-icon>
              {{ vacancy?.company?.name }}
            </div>
            <div class="box">
              <mat-icon>email</mat-icon>
              <a href="mailto:{{ vacancy?.company?.contactEmail }}">{{
                vacancy?.company?.contactEmail
              }}</a>
            </div>
            <div class="box">
              <mat-icon>call</mat-icon>
              <a href="tel:{{ vacancy?.company?.companyNumber }}">{{
                vacancy?.company?.companyNumber
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
