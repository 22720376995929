<div class="service-details-container">
  <ngx-skeleton-loader
    *ngIf="showSkeleton"
    [count]="1"
    [theme]="{ height: '20px' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    *ngIf="showSkeleton"
    [count]="1"
    [theme]="{ height: '50px' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    *ngIf="showSkeleton"
    [count]="1"
    [theme]="{ height: '30px' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    *ngIf="showSkeleton"
    [count]="1"
    [theme]="{ height: '100px' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    *ngIf="showSkeleton; else view"
    [count]="1"
    [theme]="{ height: '150px' }"
  ></ngx-skeleton-loader>
  <ng-template #view>
    <div class="service-details-header">
      <div class="location">
        <mat-icon>location_on</mat-icon>
        <span class="address">{{ service?.location?.name }}</span>
        <!--        <span>ნახვა: {{ service.views }}</span>-->
        <span class="date">{{
          service.updatedAt | date : "dd/MM/yy HH:mm"
        }}</span>
      </div>
      <div class="service-title">
        <span>{{ service.title }}</span>
      </div>
      <div class="full-name">
        <span>{{ service.fullname }}</span>
      </div>
    </div>
    <div class="statement-details-content">
      <div class="statement-details-item">
        <span class="statement-title desc">აღწერილობა</span>
        <div class="statement-text" [innerHTML]="service?.description | richText"></div>
      </div>
      <div *ngIf="service?.price" class="statement-details-item">
        <span class="statement-title desc">ფასი</span>
        <span class="statement-text">{{ service.price }}</span>
      </div>
      <div class="statement-details-item contact-card">
        <div class="box box-img">
          <img src="{{ service?.image }}" alt="img" width="150" height="150" />
        </div>
        <div class="contact-box">
          <span class="statement-title contact">კონტაქტი</span>
          <div class="statement-text contact">
            <!--            <div class="box">-->
            <!--              <mat-icon>email</mat-icon>-->
            <!--              <a href="mailto:{{ service?.email }}">{{ service?.email }}</a>-->
            <!--            </div>-->
            <div class="box">
              <mat-icon>call</mat-icon>
              <a href="tel:{{ service?.phone }}">{{ service?.phone }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
