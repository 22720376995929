import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../../environments/service/config.service';
import { Observable } from 'rxjs';
import { filterParams } from '@shared/utils';
import {
  OrderRoyalCard,
  RoyalCard,
  RoyalCardObject,
  RoyalCardObjectGetDto,
  RoyalCardObjectsFilter,
} from '@features/royal-card/models/royal-card.model';

@Injectable({
  providedIn: 'root',
})
export class RoyalCardService {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  getRoyalCards(): Observable<RoyalCard[]> {
    return this.http.get<RoyalCard[]>(
      `${this.configService.readConfig.endpoint}/royal-card`
    );
  }

  getRoyalCardCategories(): Observable<any> {
    return this.http.get<any>(
      `${this.configService.readConfig.endpoint}/royal-card-categories`
    );
  }

  getRoyalCardCategoryObjects(
    filter: RoyalCardObjectsFilter
  ): Observable<RoyalCardObjectGetDto> {
    return this.http.get<RoyalCardObjectGetDto>(
      `${this.configService.readConfig.endpoint}/facilities`,
      {
        params: filterParams(filter),
      }
    );
  }

  getRoyalCardObject(id: number): Observable<any> {
    return this.http.get<any>(
      `${this.configService.readConfig.endpoint}/facilities/${id}`
    );
  }

  orderRoyalCard(object: OrderRoyalCard): Observable<any> {
    return this.http.post<any>(
      `${this.configService.readConfig.endpoint}/order/royal-card`,
      object
    );
  }
}
