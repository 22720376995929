<footer>
  <div class="footer-container">
    <div class="contact-container">
      <div class="contact-box">
        <span class="title">კონტაქტი</span>
        <div class="icon-text cursor-pointer">
          <mat-icon>email</mat-icon>
          <a href="mailto:contact@mytelavi.ge">contact&#64;mytelavi.ge</a>
        </div>
      </div>
      <div class="contact-box">
        <span class="title">სამუშაო საათები</span>
        <div class="icon-text">
          <mat-icon class="cursor-default">schedule</mat-icon>
          <span>ყოველდღე 09:00 - 19:00</span>
        </div>
      </div>
      <div class="contact-box">
        <span class="title">შემოგვიერთდი</span>
        <div class="icon-text cursor-pointer" (click)="navigateOnFb()">
          <img
            src="assets/images/fb.png"
            alt="img"
            width="22"
            height="22"
          />
          <span>facebook.com/MyTelavi.ge</span>
        </div>
      </div>
      <div class="contact-box">
        <span class="title">წესები და პირობები</span>
        <div class="icon-text cursor-pointer" (click)="openTermsAndConditions()">
          <span>წესები და პირობები</span>
        </div>
      </div>
    </div>
    <div class="c-sign">
      <span>© 2023 - MyTelavi.ge</span>
    </div>
  </div>
</footer>
