<div class="confirm-container">
  <h5>{{ text }}</h5>
  <div class="footer-buttons">
    <button
      type="submit"
      mat-raised-button
      color="primary"
      (click)="submit.emit()"
    >
      დიახ
    </button>
    <button mat-stroked-button (click)="cancel.emit()">არა</button>
  </div>
</div>
