<div class="telavi-ads">
  <ngx-skeleton-loader
    class="cursor-pointer"
    *ngIf="showSkeleton; else view"
    [count]="1"
    [theme]="{ height: '100px' }"
  ></ngx-skeleton-loader>
  <ng-template #view>
    <img [src]="image" [title]="title ?? ''" alt="img" (click)="openNewTab()" />
    <button mat-mini-fab color="primary" title="close" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </ng-template>
</div>
