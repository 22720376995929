<form [formGroup]="form" id="registrationForm">
  <div class="title">
    <mat-label>რეგისტრაცია</mat-label>
  </div>
  <div class="form-container">
    <div class="d-container">
      <mat-form-field appearance="outline">
        <mat-label>სახელი</mat-label>
        <input matInput formControlName="firstname" />
        <mat-error *ngIf="submitted && form.controls.firstname.errors?.required"
          >შეიყვანეთ სახელი
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>გვარი</mat-label>
        <input matInput formControlName="lastname" />
        <mat-error *ngIf="submitted && form.controls.lastname.errors?.required"
          >შეიყვანეთ გვარი
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>ელ.ფოსტა</mat-label>
        <input matInput formControlName="email" />
        <mat-error *ngIf="submitted && form.controls.email.errors?.required"
          >შეიყვანეთ ელფოსტა
        </mat-error>
        <mat-error *ngIf="submitted && form.controls.email.errors?.pattern"
          >შეიყვანეთ სწორი ელფოსტა
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>ტელეფონი</mat-label>
        <input [maxlength]="9" matInput formControlName="phone" />
        <mat-icon class="get-code-icon" title="კოოდის მიღება" matSuffix>
          <span class="get-code-text" (click)="sendSms()">კოდის მიღება</span>
        </mat-icon>
        <mat-error *ngIf="submitted && form.controls.phone.errors?.required"
          >შეიყვანეთ ნომერი
        </mat-error>
        <mat-error *ngIf="submitted && form.controls.phone.errors?.minlength">
          შეიყვანეთ სწორი ნომერი
        </mat-error>
      </mat-form-field>
    </div>

    <div class="d-container-right">
      <div class="gender-age">
        <div class="gender">
          <mat-radio-group [formControl]="gender">
            <mat-radio-button [value]="1">
              <mat-icon>man</mat-icon>
              <span>მამრობითი</span>
            </mat-radio-button>
            <mat-radio-button [value]="2">
              <mat-icon>woman</mat-icon>
              <span>მდედრობითი</span>
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <app-date-picker formControlName="birthDate"></app-date-picker>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>პაროლი</mat-label>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
        />
        <mat-icon matSuffix (click)="showPassword = !showPassword">{{
          showPassword ? "visibility" : "visibility_off"
        }}</mat-icon>
        <mat-error *ngIf="submitted && form.controls.password.errors?.required">
          შეიყვანოთ სწორი პაროლი
        </mat-error>
        <mat-error *ngIf="submitted && form.controls.password.errors?.minlength"
          >შეიყვანეთ მინიმუმ 8 სიმბოლო
        </mat-error>
        <mat-error *ngIf="submitted && form.controls.password.errors?.maxlength"
          >შეიყვანეთ მაქსიმუმ 32 სიმბოლოს
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>გაიმეორეთ პაროლი</mat-label>
        <input
          matInput
          type="password"
          formControlName="confirm_password"
          [type]="showConfirmPassword ? 'text' : 'password'"
        />
        <mat-icon
          matSuffix
          (click)="showConfirmPassword = !showConfirmPassword"
        >
          {{ showConfirmPassword ? "visibility" : "visibility_off" }}
        </mat-icon>
        <mat-error
          *ngIf="submitted && form.controls.confirm_password.errors?.required"
          >გაიმეორეთ პაროლი
        </mat-error>
        <mat-error
          *ngIf="
            submitted && form.controls.confirm_password.errors?.passwordMismatch
          "
          >პაროლები უნდა ემთხვეოდეს ერთმანეთს
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>SMS კოდი</mat-label>
        <input matInput formControlName="code" />
        <mat-error *ngIf="submitted && form.controls.code.errors?.required"
          >SMS კოდი სავალდებულოა
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="terms-and-privacy">
    <div class="terms">
      <div class="terms-and-privacy-div">
        <mat-checkbox class="example-margin" formControlName="terms">
        </mat-checkbox>
        ვეთანხმები<a (click)="openTermsAndConditions()">წესებსა და პირობებს</a>
      </div>
      <mat-error *ngIf="submitted && form.controls.terms?.errors?.required"
        >დაეთანხმეთ საიტის წესებსა და პირობებს
      </mat-error>
    </div>
    <!--    <div class="terms-and-privacy-div">-->
    <!--      <mat-checkbox class="example-margin" formControlName="privacy">-->
    <!--      </mat-checkbox>-->
    <!--      ვეთანხმები<a>კონფიდენციალობის პოლიტიკას</a>-->
    <!--    </div>-->
  </div>
  <div class="footer-buttons">
    <button
      type="submit"
      mat-raised-button
      color="primary"
      form="registrationForm"
      [disabled]="loading"
      (click)="onSubmit()"
    >
      დადასტურება
    </button>
  </div>
</form>
