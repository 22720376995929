<div class="my-t-header">
  <mat-form-field class="hide-wrapper" appearance="outline">
    <mat-label>სათაური/აღწერა</mat-label>
    <input matInput name="search" [(ngModel)]="filter.search" (ngModelChange)="search()"/>
  </mat-form-field>

  <mat-form-field class="hide-wrapper select" [class.selected]="filter.categoryId" appearance="outline">
    <mat-label>კატეგორია</mat-label>
    <mat-select name="categoryId" [(ngModel)]="filter.categoryId" (ngModelChange)="filterChange.emit(filter)">
      <mat-option
        *ngFor="let category of categories$ | async"
        [value]="category.id"
      >
        {{ category.name }}
      </mat-option>
    </mat-select>
    <mat-icon *ngIf="filter.categoryId" (click)="clearCategory()">close</mat-icon>
  </mat-form-field>

  <mat-form-field class="hide-wrapper select" [class.selected]="filter.locationId" appearance="outline">
    <mat-label>მდებარეობა</mat-label>
    <mat-select name="locationId" [(ngModel)]="filter.locationId" (ngModelChange)="filterChange.emit(filter)">
      <mat-option
        *ngFor="let location of locations$ | async"
        [value]="location.id"
      >
        {{ location.name }}
      </mat-option>
    </mat-select>
    <mat-icon *ngIf="filter.locationId" (click)="clearLocation()">close</mat-icon>
  </mat-form-field>
</div>
