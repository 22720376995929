<div class="buttons">
  <button
    class="upload"
    mat-raised-button
    color="primary"
    (click)="inputRef.click()"
  >
    <mat-icon>upload</mat-icon>
  </button>

  <input
    type="file"
    accept="image/*"
    [multiple]="multiple"
    (change)="selectFiles($event)"
    #inputRef
  />

  <!--  <button-->
  <!--    mat-raised-button-->
  <!--    color="primary"-->
  <!--    [disabled]="!selectedFiles"-->
  <!--    (click)="uploadFiles()"-->
  <!--  >-->
  <!--    Upload-->
  <!--  </button>-->
</div>

<div
  *ngIf="previews.length > 0"
  class="images"
  cdkDropList
  cdkDropListOrientation="vertical"
  (cdkDropListDropped)="drop($event)"
>
  <ng-container *ngFor="let preview of previews; index as i">
    <div class="img-box">
      <img [src]="preview.image ?? preview" cdkDrag [cdkDragDisabled]="!draggable" (cdkDragStarted)="showX = false"/>
      <mat-icon *ngIf="showX" (click)="delete.emit(preview.id)">close</mat-icon>
    </div>
  </ng-container>
</div>
