<svg
  attr.viewBox="0 0 {{ size }} {{ size }}"
  attr.width="{{ width }}rem"
  attr.height="{{ height }}rem"
  attr.fill="{{ color }}"
  [ngStyle]="{
    'transform': 'rotate(' + rotate + 'deg)',
    '-webkit-transform': 'rotate(' + rotate + 'deg)',
    '-ms-transform': 'rotate(' + rotate + 'deg)'
  }"
>
  <use [attr.href]="src"></use>
</svg>
