import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.scss'],
})
export class UploadImagesComponent implements OnInit {
  @Input() set images(values: any[]) {
    if (values?.length > 0) this.previews = values;
  }

  @Input() multiple?: boolean = false;
  @Input() showX?: boolean = false;
  @Input() draggable?: boolean = false;
  @Output() upload = new EventEmitter<any>();
  @Output() delete = new EventEmitter<number>();

  selectedFiles: any[] = [];

  previews: any[] = [];

  constructor() {}

  ngOnInit(): void {}

  selectFiles(event: any): void {
    this.selectedFiles = Array.from(event.target.files);

    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }

    if (this.selectedFiles?.length > 0) this.uploadFiles();
  }

  uploadFiles(): void {
    if (this.selectedFiles) {
      this.upload.emit(this.selectedFiles);
    }
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.previews, event.previousIndex, event.currentIndex);
    this.upload.emit(this.previews);
    this.showX = true;
  }
}
