import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent {
  @Output() accept = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }
}
