import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../environments/service/config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  getLocations(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.configService.readConfig.endpoint}/locations`
    );
  }
}
