<div *ngIf="!forgetPassword" class="login-container">
  <form [formGroup]="form" id="loginForm">
    <div class="login-body">
      <div class="title">
        <mat-label>შესვლა</mat-label>
      </div>
      <mat-form-field class="phone" appearance="outline">
        <mat-label>ტელეფონი</mat-label>
        <input [maxlength]="9" matInput formControlName="phone" />
        <mat-error *ngIf="submitted && form.controls.phone.errors?.minlength || form.controls.phone.errors?.numbersOnly">
          შეიყვანეთ სწორი ნომერი
        </mat-error>
      </mat-form-field>

      <mat-form-field class="password" appearance="outline">
        <mat-label>პაროლი</mat-label>
        <input
          matInput
          formControlName="password"
          [type]="showPassword ? 'text' : 'password'"
        />
        <mat-icon matSuffix (click)="showPassword = !showPassword">{{
          showPassword ? "visibility" : "visibility_off"
        }}</mat-icon>
      </mat-form-field>
      <div class="recoverPassword">
        <mat-label (click)="navigateToRecoverPassword()">პაროლის აღდგენა</mat-label>
      </div>
    </div>

    <div class="footer-buttons">
      <button
        mat-raised-button
        color="primary"
        form="loginForm"
        [disabled]="loading"
        (click)="onSubmit()"
      >
        შესვლა
      </button>
      <a
        class="registration"
        mat-stroked-button
        target="_blank"
        (click)="navigateToSignUp()"
        >რეგისტრაცია</a
      >
    </div>
  </form>
</div>
