<div
  id="container"
  class="container"
  #container
  infinite-scroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  (scrolled)="scrolled()"
  (scroll)="onScroll()"
  [class.show]="!loading"
>
  <div class="header">
    <app-header></app-header>
  </div>
  <div class="content" #content>
    <ng-container *ngIf="!showLoading">
      <router-outlet></router-outlet>
    </ng-container>
    <div *ngIf="showLoading" class="center-page">
      <div class="sk-cube-grid">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<div *ngIf="showInstructions" class="instruction">
  <button mat-fab (click)="openInstructions()">
    <mat-icon>question_mark</mat-icon>
  </button>
</div>
