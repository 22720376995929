import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @HostBinding('style.width.rem')
  @Input()
  width?: number;

  @HostBinding('style.height.rem')
  @Input()
  height?: number;

  @Input() set name(name: string) {
    this.src = `assets/icons/${name}.svg#${name}`;
  }

  @Input() size: number = 24;
  @Input() color: string = 'currentColor';
  @Input() rotate: number = 0;

  src!: string;

  constructor() {}

  ngOnInit(): void {
    if (!this.width || !this.height) {
      this.width = this.size / 16;
      this.height = this.size / 16;
    }
  }
}
