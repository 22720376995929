import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RoyalCardService } from '@features/royal-card/services/royal-card.service';
import { ToastrService } from 'ngx-toastr';
import { customEmailValidator, customPhoneValidator } from '@shared/utils';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoyalCard } from '@features/royal-card/models/royal-card.model';

@Component({
  selector: 'app-order-royal-card',
  templateUrl: './order-royal-card.component.html',
  styleUrls: ['./order-royal-card.component.scss'],
})
export class OrderRoyalCardComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<void>();
  form: FormGroup | any;
  loading = false;
  submitted = false;

  royalCards$!: Observable<RoyalCard[]>;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private royalCardService: RoyalCardService,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.createForm();
    if (data?.royalCardId) {
      this.form.get('royalCardId').setValue(data.royalCardId);
    }
  }

  ngOnInit() {
    this.getRoyalCards();
  }

  getRoyalCards() {
    this.royalCards$ = this.royalCardService.getRoyalCards();
  }

  createForm() {
    this.form = new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, customEmailValidator]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
        customPhoneValidator,
      ]),
      address: new FormControl('', Validators.required),
      royalCardId: new FormControl('', Validators.required),
    });
  }

  orderRoyalCard() {
    this.royalCardService
      .orderRoyalCard({
        ...this.form.value,
        royalCardId: this.form.value.royalCardId.toString(),
      })
      .pipe(
        tap({
          next: () => {
            this.toast.success('თქვენი შეკვეთა მიღებულია');
            this.close.emit();
          },
          error: () => {
            this.loading = false;
          },
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.form.valid) {
      this.orderRoyalCard();
    } else {
      this.toast.error('ფორმა არასწორია');
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
