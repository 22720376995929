<ng-container *ngIf="!showNewPasswordForm">
  <div class="recover-container">
    <form [formGroup]="recoverConfirmForm" id="recoverConfirmForm">
      <div class="recover-body">
        <div class="title">
          <mat-label>პაროლის აღდგენა</mat-label>
        </div>
<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>ელფოსტა</mat-label>-->
<!--          <input matInput formControlName="email" />-->
<!--          <mat-error-->
<!--            *ngIf="-->
<!--              recoverConfirmFormSubmitted &&-->
<!--              recoverConfirmForm.controls.email.errors?.required-->
<!--            "-->
<!--            >შეიყვანეთ ელფოსტა-->
<!--          </mat-error>-->
<!--          <mat-error-->
<!--            *ngIf="-->
<!--              recoverConfirmFormSubmitted &&-->
<!--              recoverConfirmForm.controls.email.errors?.pattern-->
<!--            "-->
<!--            class="email-error"-->
<!--            >შეიყვანეთ სწორი ელფოსტა-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->

        <mat-form-field appearance="outline">
          <mat-label>ტელეფონი</mat-label>
          <input [maxlength]="9" matInput formControlName="phone" />
          <mat-icon class="get-code-icon" title="კოოდის მიღება" matSuffix>
            <span class="get-code-text" (click)="sendSms()">კოდის მიღება</span>
          </mat-icon>
          <mat-error *ngIf="recoverConfirmFormSubmitted && recoverConfirmForm.controls.phone.errors?.required"
          >შეიყვანეთ ნომერი
          </mat-error>
          <mat-error *ngIf="recoverConfirmFormSubmitted && recoverConfirmForm.controls.phone.errors?.minlength">
            შეიყვანეთ სწორი ნომერი
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>SMS კოდი</mat-label>
          <input matInput formControlName="code" />
          <mat-error
            *ngIf="
              recoverConfirmFormSubmitted &&
              recoverConfirmForm.controls.code.errors?.required
            "
            >SMS კოდი სავალდებულოა
          </mat-error>
        </mat-form-field>
      </div>

      <div class="footer-buttons">
        <button
          mat-raised-button
          color="primary"
          form="recoverConfirmForm"
          [disabled]="loading"
          (click)="submitRecoverConfirmForm()"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          დადასტურება
        </button>
      </div>
    </form>
  </div>
</ng-container>

<ng-container *ngIf="showNewPasswordForm">
  <div class="recover-container">
    <form [formGroup]="newPasswordForm" id="newPasswordForm">
      <div class="recover-body">
        <div class="title">
          <mat-label>შეიყვანეთ ახალი პაროლი</mat-label>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>ახალი პაროლი</mat-label>
          <input
            matInput
            [type]="showPassword ? 'text' : 'password'"
            formControlName="password"
          />
          <mat-icon matSuffix (click)="showPassword = !showPassword">{{
            showPassword ? "visibility" : "visibility_off"
          }}</mat-icon>
          <mat-error
            *ngIf="
              newPasswordFormSubmitted &&
              newPasswordForm.controls.password.errors?.required
            "
          >
            შეიყვანოთ სწორი პაროლი
          </mat-error>
          <mat-error
            *ngIf="
              newPasswordFormSubmitted &&
              newPasswordForm.controls.password.errors?.minlength
            "
          >
            შეიყვანეთ მინიმუმ 8 სიმბოლო
          </mat-error>
          <mat-error
            *ngIf="
              newPasswordFormSubmitted &&
              newPasswordForm.controls.password.errors?.maxlength
            "
          >
            შეიყვანეთ მაქსიმუმ 32 სიმბოლოს
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>გაიმეორეთ პაროლი</mat-label>
          <input
            matInput
            type="password"
            formControlName="confirm_password"
            [type]="showConfirmPassword ? 'text' : 'password'"
          />
          <mat-icon
            matSuffix
            (click)="showConfirmPassword = !showConfirmPassword"
            >{{
              showConfirmPassword ? "visibility" : "visibility_off"
            }}</mat-icon
          >
          <mat-error
            *ngIf="
              newPasswordFormSubmitted &&
              newPasswordForm.controls.confirm_password.errors?.required
            "
            >გაიმეორეთ პაროლი
          </mat-error>
          <mat-error
            *ngIf="
              newPasswordFormSubmitted &&
              newPasswordForm.controls.confirm_password.errors?.passwordMismatch
            "
            >პაროლები უნდა ემთხვეოდეს ერთმანეთს
          </mat-error>
        </mat-form-field>
      </div>

      <div class="footer-buttons">
        <button
          mat-raised-button
          color="primary"
          form="newPasswordForm"
          [disabled]="loading"
          (click)="submitNewPasswordForm()"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          დადასტურება
        </button>
      </div>
    </form>
  </div>
</ng-container>
