import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class StorageService {
  public set(key: string, value: string): StorageService {
    localStorage.setItem(key, value);
    return this;
  }

  public get(key: string, $default: any = null): Observable<string> {
    const data: string | null = localStorage.getItem(key);
    if (data) {
      return of(data);
    }
    return of($default);
  }

  public remove(key: string) {
    localStorage.removeItem(key);
  }
}
