import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import { BaseIdNameDate } from '@shared/models/base.model';
import { LocationsService } from '@shared/services/locations.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() categories$!: Observable<BaseIdNameDate[]>;
  @Input() filter: any = {
    page: 1,
    limit: 10,
    search: '',
    categoryId: '',
    locationId: '',
  };
  @Output() filterChange = new EventEmitter<any>();

  locations$!: Observable<BaseIdNameDate[]>;
  searchSubject = new Subject<string>();
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private locationsService: LocationsService) {}

  ngOnInit() {
    this.getLocations();
    this.searchSubjectPipe();
  }

  getLocations() {
    this.locations$ = this.locationsService.getLocations();
  }

  searchSubjectPipe() {
    this.searchSubject
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterChange.emit(this.filter);
      });
  }

  search() {
    this.searchSubject.next(this.filter.search);
  }

  clearLocation() {
    this.filter = {
      ...this.filter,
      locationId: '',
    };
    this.filterChange.emit(this.filter);
  }

  clearCategory() {
    this.filter = {
      ...this.filter,
      categoryId: '',
    };
    this.filterChange.emit(this.filter);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
