<div class="my-t-slider-container">
  <div class="my-t-slider-header" [class.space]="showSkeleton">
    <span class="title cursor-pointer" [routerLink]="'/' + link">{{
      title
    }}</span>
    <div class="my-t-slider-arrow-scroll">
      <mat-icon (click)="scrollLeft()" [class.opacity]="!isScrollLeftEnabled"
        >keyboard_arrow_left</mat-icon
      >
      <mat-icon (click)="scrollRight()" [class.opacity]="!isScrollRightEnabled"
        >keyboard_arrow_right</mat-icon
      >
    </div>
  </div>
  <ngx-skeleton-loader
    *ngIf="showSkeleton; else view"
    [count]="1"
    [theme]="{ height: '100px' }"
  ></ngx-skeleton-loader>
  <ng-template #view>
    <div class="my-t-slider" #sliderRef (scroll)="updateScrollArrows()">
      <ng-container *ngFor="let item of list ?? []; index as i">
        <div class="my-t-slider-item" (click)="navigate(item.link)">
          <div class="my-t-slider-item-image">
            <img [src]="item.image" [class.object-fit]="objectFit" />
          </div>
          <ng-container
            *ngIf="item.description?.date && item.description?.time"
          >
            <div class="my-t-slider-item-description">
              <mat-icon>schedule</mat-icon>
              <span
                >{{ item.description.time }}, {{ item.description.date }}</span
              >
            </div>
          </ng-container>
          <div class="my-t-slider-item-title">
            <span>{{ item.title }}</span>
          </div>
        </div>

        <div
          *ngIf="i == list.length - 1"
          class="my-t-slider-item"
          (click)="navigateToMain(item.link)"
        >
          <div class="show-all">
            <span>ყველას ნახვა</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
