import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterLink } from '@angular/router';
import { ConfirmationComponent } from '@shared/components/confirmation/confirmation.component';
import { IconComponent } from '@shared/components/icon/icon.component';
import { ServiceDetailsComponent } from '@shared/components/service-details/service-details.component';
import { TermsAndConditionsComponent } from '@shared/components/terms-and-conditions/terms-and-conditions.component';
import { UploadImagesComponent } from '@shared/components/upload-images/upload-images.component';
import { VacancyDetailsComponent } from '@shared/components/vacancy-details/vacancy-details.component';
import { MatTabScrollToCenterDirective } from '@shared/directives/mat-tab-scroll.directive';
import { SwiperDirective } from '@shared/directives/swiper.directive';
import { MaterialModule } from '@shared/modules/material.module';
import { EditorComponent, EditorModule } from '@tinymce/tinymce-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FilterComponent } from './components/filter/filter.component';
import { InstructionComponent } from './components/instruction/instruction.component';
import { MyTelaviAdsComponent } from './components/my-telavi-ads/my-telavi-ads.component';
import { MyTelaviSliderComponent } from './components/my-telavi-slider/my-telavi-slider.component';
import { OrderRoyalCardComponent } from './components/order-royal-card/order-royal-card.component';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { RichTextPipe } from './pipes/rich-text.pipe';

@NgModule({
  declarations: [
    IconComponent,
    MatTabScrollToCenterDirective,
    ConfirmationComponent,
    UploadImagesComponent,
    VacancyDetailsComponent,
    ServiceDetailsComponent,
    OutsideClickDirective,
    MyTelaviSliderComponent,
    OrderRoyalCardComponent,
    InstructionComponent,
    TermsAndConditionsComponent,
    RichTextPipe,
    FilterComponent,
    SwiperDirective,
    MyTelaviAdsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    DragDropModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    RouterLink,
    FormsModule,
    NgxSkeletonLoaderModule,
    EditorModule,
    EditorComponent,
  ],
  exports: [
    IconComponent,
    MatTabScrollToCenterDirective,
    MaterialModule,
    ConfirmationComponent,
    UploadImagesComponent,
    DragDropModule,
    InfiniteScrollModule,
    VacancyDetailsComponent,
    ServiceDetailsComponent,
    OutsideClickDirective,
    MyTelaviSliderComponent,
    OrderRoyalCardComponent,
    TermsAndConditionsComponent,
    RichTextPipe,
    FilterComponent,
    SwiperDirective,
    NgxSkeletonLoaderModule,
    FormsModule,
    MyTelaviAdsComponent,
    EditorModule,
    EditorComponent,
  ],
  providers: [DatePipe, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
