import { Injectable } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private router: Router, private meta: Meta, private title: Title) {}

  updateMetaTags(object: MetaTags) {
    this.title.setTitle('MyTelavi - ' + object.tabTitle);
    // Open Graph / Facebook
    this.meta.updateTag({ property: 'og:title', content: object.title });
    this.meta.updateTag({
      property: 'og:description',
      content: object.description,
    });
    this.meta.updateTag({ property: 'og:image', content: object.image });
    this.meta.updateTag({ property: 'og:url', content: object.url });

    // Twitter
    this.meta.updateTag({ property: 'twitter:url', content: object.url });
    this.meta.updateTag({ property: 'twitter:title', content: object.title });
    this.meta.updateTag({
      property: 'twitter:description',
      content: object.description,
    });
    this.meta.updateTag({ property: 'twitter:image', content: object.image });
  }
}

export interface MetaTags {
  tabTitle: string;
  title: string;
  description: string;
  image: string;
  url: string;
}
