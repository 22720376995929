import { NgForOf, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from '@core/authentication/auth-routing.module';
import { LoginPageComponent } from '@core/authentication/components/login/login-page.component';
import { RecoverPasswordComponent } from '@core/authentication/components/recover/recover-password.component';
import { RegistrationComponent } from '@core/authentication/components/registration/registration.component';
import { AuthService } from '@core/authentication/services';
import { SharedModule } from '@shared/shared.module';
import { DatePickerComponent } from '../../shared/components/inputs/date-picker/date-picker.component';

@NgModule({
  declarations: [
    RegistrationComponent,
    LoginPageComponent,
    RecoverPasswordComponent,
  ],
  providers: [AuthService],
  exports: [],
  imports: [
    AuthRoutingModule,
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    SharedModule,
    DatePickerComponent,
  ],
})
export class AuthModule {}
