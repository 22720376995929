import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthValidationService } from '@core/authentication/services/auth-validation/auth-validation.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@core/authentication';
import { Subject, takeUntil, tap } from 'rxjs';
import { Router } from '@angular/router';
import { customPhoneValidator } from '@shared/utils';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
  @Output() recovered = new EventEmitter();

  private destroy$: Subject<void> = new Subject<void>();

  recoverConfirmForm: FormGroup | any;
  newPasswordForm: FormGroup | any;
  loading: boolean = false;
  showNewPasswordForm: boolean = false;
  showConfirmPassword: boolean = false;
  recoverConfirmFormSubmitted: boolean = false;
  newPasswordFormSubmitted: boolean = false;
  showPassword: boolean = false;

  get passwordValue() {
    return this.newPasswordForm.get('password').value;
  }

  get passwordControl(): AbstractControl {
    return this.newPasswordForm.get('password');
  }

  get confirmPasswordControl() {
    return this.newPasswordForm.get('confirm_password');
  }

  constructor(
    private authValidationService: AuthValidationService,
    private authService: AuthService,
    private toast: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.recoverConfirmForm = new FormGroup({
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
        customPhoneValidator,
      ]),
      code: new FormControl(null, Validators.required),
    });
  }

  sendSms() {
    if (
      this.recoverConfirmForm.controls.phone?.valid &&
      this.recoverConfirmForm.value?.phone
    ) {
      this.authService
        .sendSms({ phone: this.recoverConfirmForm.value?.phone })
        .pipe(
          tap(() => {
            this.toast.success('კოდი გაგზავნილია ნომერზე');
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.toast.error('გთხოვთ შეიყვანოთ ტელეფონის ნომერი');
    }
  }

  submitRecoverConfirmForm() {
    this.recoverConfirmFormSubmitted = true;
    if (this.recoverConfirmForm.valid) {
      this.authService
        .smsVerify(this.recoverConfirmForm.value)
        .pipe(
          tap({
            next: (res) => {
              this.createNewPasswordForm();
              this.showNewPasswordForm = true;
            },
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.toast.error('ფორმა არასწორია');
    }
  }

  createNewPasswordForm() {
    this.newPasswordForm = new FormGroup(
      {
        phone: new FormControl(this.recoverConfirmForm.value.phone),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
        ]),
        confirm_password: new FormControl(
          '',
          Validators.compose([Validators.required])
        ),
      },
      this.authValidationService.passwordMatch('password', 'confirm_password')
    );
  }

  submitNewPasswordForm() {
    this.newPasswordFormSubmitted = true;
    if (this.newPasswordForm.valid) {
      this.authService
        .forgotPassword(this.newPasswordForm.value)
        .pipe(
          tap({
            next: (res) => {
              this.toast.success('წარმატებით განახლდა პაროლი');
              this.router.navigate(['dashboard']).then();
            },
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.toast.error('ფორმა არასწორია');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
