import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VacanciesService } from '@features/vacancies/services/vacancies.service';
import { Vacancy } from '@features/vacancies/models/vacancies.model';
import { Subject, takeUntil, tap } from 'rxjs';
import { MetaService } from '@shared/services/meta.service';

@Component({
  selector: 'app-vacancy-details',
  templateUrl: './vacancy-details.component.html',
  styleUrls: ['./vacancy-details.component.scss'],
})
export class VacancyDetailsComponent implements OnInit, OnDestroy {
  vacancy: Vacancy | any;
  showSkeleton = true;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private vacancyService: VacanciesService,
    private metaService: MetaService
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const id = +params['id'];
      this.getVacancyById(id);
    });
  }

  getVacancyById(vacancyId: number) {
    if (vacancyId)
      this.vacancyService
        .getVacancyById(vacancyId)
        .pipe(
          tap({
            next: (res: any | Vacancy) => {
              this.vacancy = res.vacancy;
              this.metaService.updateMetaTags({
                tabTitle: 'ვაკანსიები',
                title: this.vacancy.title,
                description: this.vacancy.description,
                image: this.vacancy.company.logo,
                url: `https://mytelavi.ge/vacancies/details/${this.vacancy.id}`,
              });
              this.showSkeleton = false;
            },
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
