import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toast: ToastrService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          if (!this.authService.getUser()) {
            this.toast.error(err.error.message);
          } else {
            if (req.url.includes('auth/refresh')) {
              this.router.navigate(['login']).then();
            } else {
              return this.handle401Error(req, next);
            }
          }
        } else if (
          err instanceof HttpErrorResponse &&
          (err.status == 500 ||
            err.status == 501 ||
            err.status == 502 ||
            err.status == 503 ||
            err.status == 504 ||
            err.status == 505 ||
            err.status == 506 ||
            err.status == 507 ||
            err.status == 508 ||
            err.status == 509 ||
            err.status == 5010 ||
            err.status == 5011)
        ) {
          this.toast.error('მონაცემების დამუშავებისას მოხდა შეცდომა');
        } else {
          this.toast.error(err.error?.message ?? 'error', '', {
            timeOut: 50000000,
          });
        }
        return throwError(err);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshToken().pipe(
      switchMap((res) => {
        return next.handle(request);
      }),
      catchError((refreshError) => {
        this.authService.clearStorage();
        return throwError(refreshError);
      })
    );
  }
}
