import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-telavi-slider',
  templateUrl: './my-telavi-slider.component.html',
  styleUrls: ['./my-telavi-slider.component.scss'],
})
export class MyTelaviSliderComponent implements OnInit {
  @ViewChild('sliderRef') sliderRef: ElementRef | any;
  @Input() title: string = '';
  @Input() set list(value: any) {
    if (value?.length) {
      this._list = value;
      this.showSkeleton = false;
    }
  }
  @Input() link: string = '';
  @Input() objectFit: boolean = true;

  get list() {
    return this._list;
  }

  private _list: any = [];
  isScrollLeftEnabled = false;
  isScrollRightEnabled = true;
  showSkeleton = true;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigate(link: string) {
    this.router.navigate([link]).then();
  }

  navigateToMain(link: string) {
    const indexOfSlash = link.indexOf('/');
    if (indexOfSlash !== -1) {
      const cutString = link.substring(0, indexOfSlash);
      this.router.navigate([cutString]).then();
    }
  }

  scrollLeft(): void {
    if (this.sliderRef) this.sliderRef.nativeElement.scrollLeft -= 300;
  }

  scrollRight(): void {
    if (this.sliderRef) this.sliderRef.nativeElement.scrollLeft += 300;
  }

  updateScrollArrows(): void {
    if (this.sliderRef) {
      const container = this.sliderRef.nativeElement;
      this.isScrollLeftEnabled = container.scrollLeft > 0;
      this.isScrollRightEnabled =
        container.scrollLeft + 1 <
        container.scrollWidth - container.clientWidth;
    }
  }
}
