<header #ref>
  <div class="img" [routerLink]="'dashboard'">
    <img
      id="nav-dashboard"
      class="erekle"
      src="assets/icons/erekle.svg"
      alt="logo"
    />
    <img src="assets/icons/mytelavi-ge.svg" class="mytelavi" alt="mytelavi" />
  </div>
  <nav
    *ngIf="!showSearch && ref.offsetWidth > 450"
    [class.center]="authService.isAuthenticated()"
  >
    <div class="nav-all" *ngFor="let route of routes.slice(0, 6); index as i">
      <a
        id="nav-{{ route.path }}"
        class="nav-{{ i }}"
        routerLinkActive="active"
        [routerLink]="route.path"
      >
        <span>
          {{ route.name }}
        </span>
      </a>
    </div>
  </nav>
  <div class="header-buttons">
    <div class="search" [class.unset-opacity]="showSearch">
      <app-icon
        *ngIf="!showSearch"
        [name]="'search'"
        (click)="showSearch = true"
      ></app-icon>
      <div *ngIf="showSearch" class="header-search-input">
        <div class="search-input-box">
          <mat-form-field class="hide-wrapper">
            <input
              type="text"
              placeholder="ძიება"
              matInput
              [(ngModel)]="filter.search"
              (ngModelChange)="search()"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option
                *ngFor="let option of options"
                [value]="option"
                (onSelectionChange)="onSelectionChange(option)"
              >
                <img
                  src="{{ option.image }}"
                  width="50px"
                  height="50px"
                  alt="image"
                />
                <span class="option-title">{{ option.title }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-icon (click)="onClose()">close</mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="showLogin && !authService.isAuthenticated()" class="login">
      <button
        class="default user"
        mat-raised-button
        color="accent"
        placeholder="login"
        (click)="login()"
      >
        <app-icon class="icon" [name]="'user'" [size]="16"></app-icon>
        <span>შესვლა</span>
      </button>
    </div>
    <div class="burger-menu">
      <app-icon name="burger-menu" (click)="showMenu = true"></app-icon>
    </div>
  </div>
</header>

<ng-container *ngIf="showMenu">
  <div
    class="menu-container"
    [appOutsideClickActive]="showMenu"
    (appOutsideClick)="hideMenuAndDetails()"
  >
    <div class="menu-header" [class.authed]="authService.isAuthenticated()">
      <div
        class="user"
        *ngIf="authService.isAuthenticated()"
        (click)="showDetails = !showDetails"
      >
        <mat-icon>account_circle</mat-icon>
        <span>{{ authService.getUser()?.fullName }}</span>
      </div>
      <app-icon name="close" (click)="showMenu = false"></app-icon>
    </div>
    <div class="menu-body">
      <ng-container *ngIf="!showDetails">
        <ng-container *ngFor="let route of routes">
          <a
            id="menu-nav-{{ route.path }}"
            [routerLink]="route.path"
            routerLinkActive="active"
            (click)="showMenu = false"
          >
            <span>
              {{ route.name }}
            </span>
          </a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="showDetails && authService.isAuthenticated()">
        <a
          id="menu-nav-my-profile"
          [routerLink]="'my-profile'"
          (click)="hideMenuAndDetails()"
        >
          <span> ჩემი პროფილი </span>
        </a>
        <a
          id="menu-nav-my-statement"
          [routerLink]="'my-statements'"
          (click)="hideMenuAndDetails()"
        >
          <span> ჩემი განცხადებები </span>
        </a>
      </ng-container>
    </div>
    <div class="menu-footer">
      <div></div>
      <div
        *ngIf="authService.isAuthenticated()"
        class="logout"
        (click)="logout()"
      >
        <mat-icon>logout</mat-icon>
        <span>გამოსვლა</span>
      </div>
    </div>
  </div>
</ng-container>
